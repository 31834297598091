import Axios from "axios"
import { BASE_URL } from "constants/api/routes"
import { LOCALSTORAGE_KEY } from "constants/localStorage"
import { css } from "styled-components"
import { format } from "date-fns"
import { getData } from "country-list"
import lo from "lodash"
/* eslint-disable */
import { message } from "antd"
import { useEffect } from "react"
import { useLocation } from "react-router-dom"

/**
 * roundUpNumber to upper limit
 * @param {number} amount
 * @returns number
 */
export const roundUpNumber = (amount) => Math.ceil(amount / 1000) * 1000

/**
 * format string to currency string
 * @param {string} currencyCode
 * @returns string
 */
export const moneyFormatter = (currencyCode) => {
	return currencyCode
		? new Intl.NumberFormat("en-US", {
				style: "currency",
				currency: currencyCode,
				// These options are needed to round to whole numbers if that's what you want.
				//minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
				//maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
		  })
		: { format: (val) => val }
}

/**
 * function to get exchange kurs from e-declaration
 * @param {string} currencyCode
 */
export const exRate = (currencyCode) => {
	fetch("https://e-declaration.trade2gov.com/checkkurs", {
		method: "post",
		body: JSON.stringify({
			valuta: currencyCode,
		}),
	}).then(function (response) {
		const result = response.json()
		return result?.KURS || "0"
	})
}

export const numberOrString = (key = "", thing = {}, defaults = []) => {
	if (typeof thing === "number" || typeof thing === "string") return thing
	const firstChar = key.slice(0, 1)
	const directions = {
		top: thing[firstChar + "t"] || defaults[0],
		right: thing[firstChar + "r"] || defaults[1],
		bottom: thing[firstChar + "b"] || defaults[2],
		left: thing[firstChar + "l"] || defaults[3],
	}
	return `${directions.top} ${directions.right} ${directions.bottom} ${directions.left}`
}

Array.prototype.sum = function (prop) {
	var total = 0
	for (var i = 0, _len = this.length; i < _len; i++) {
		total += this[i][prop]
	}
	return total
}

// Media queries Styled-Components
const sizes = {
	tablet: 767,
	mobile: 414,
}

export const media = Object.keys(sizes).reduce((acc, label) => {
	acc[label] = (...args) => css`
		@media (max-width: ${sizes[label]}px) {
			${css(...args)};
		}
	`
	return acc
}, {})

/**
 * format price
 * @param {number | bigint} price
 * @param {boolean} withComma
 * @returns string
 */
export const formatPrice = (price, withComma = false) => {
	const theValue = new Intl.NumberFormat("id-ID", {
		currency: "IDR",
	}).format(price)

	if (withComma) return theValue + ",00"
	return theValue
}

export default function ScrollToTop() {
	const { pathname } = useLocation()

	useEffect(() => {
		window.scrollTo(0, 0)
	}, [pathname])

	return null
}

export async function allCountryOptions() {
	// const data = await fetch(`https://restcountries.eu/rest/v2/all`)
	const data = await fetch(`https://restcountries.com/v2/all`)
	const countries = await data.json()
	return countries
}

export const countryList = getData().map((country) => ({
	value: country.code,
	label: country.name,
}))

export const allCountryCode = getData().map((country) => ({
	value: country.code,
	label: `${country.code} - ${country.name}`,
}))

export const allCountryCurrencyCode = () => {
	const countryOptions = JSON.parse(localStorage.getItem(LOCALSTORAGE_KEY.COUNTRIES))

	if (!countryOptions || !Array.isArray(countryOptions)) {
		return [
			{
				value: "",
				label: "",
			},
		]
	}

	const currencies = countryOptions.map((c) => {
		return {
			value: c.currencies?.[0].code,
			label: c.currencies?.[0].name + " - " + c.currencies?.[0].symbol,
		}
	})

	return lo.uniqBy(currencies, "value")
}

/**
 * function to get country name based on country code
 * @param {string} code country name eg: ID
 * @returns string
 */
export function getCountryName(code) {
	const countryCode = code.trim().toLowerCase()
	const result = getData().find((country) => country.code.toLowerCase() === countryCode)

	return result.name
}

/**
 * function to get country code based on country name
 * @param {string} _countryName country name eg: Indonesia
 * @returns string
 */
export function getCountryCode(_countryName) {
	const countryName = _countryName.trim().toLowerCase()
	const result = getData().find((country) => country.name.toLowerCase() === countryName)

	return result?.code
}

export const actionCb = ({ data }) => {
	if (!data.isSuccess && data.errorMessage) return message.error(data.errorMessage, 3)
	return data
}

const instance = Axios.create({
	baseURL: BASE_URL,
	withCredentials: true,
	headers: {
		Accept: "application/json",
		"Access-Control-Allow-Origin": "*",
		// Authorization: `Bearer ${token}`
	},
})

instance.interceptors.request.use((config) => {
	const token = localStorage.getItem(LOCALSTORAGE_KEY.ACCESS_TOKEN)
	config.headers["Authorization"] = `Bearer ${token}`
	return config
})

instance.interceptors.response.use(
	(res) => res,
	(err) => {
		if (err.response.status === 401) {
			localStorage.clear()
			const errMessage = "You're not authorized (err: 401)"
			message.error(errMessage, 10)
			window.location.replace("/login")
			throw new Error(errMessage)
		}
		throw err
	},
)

export { instance }

export const fetcher = (url) => {
	return instance
		.get(url)
		.then(({ data }) => data)
		.catch((err) => {
			console.error({ fetcherErr: err.response })
			if (err.response.status === 401) {
				localStorage.clear()
				message.error("You're not authorized", 10)
			}
		})
}

// https://stackoverflow.com/questions/1353684/detecting-an-invalid-date-date-instance-in-javascript
/**
 * check if date is valid Date
 * @param {Date} d
 * @returns boolean
 */
export function isValidDate(d) {
	return d instanceof Date && !isNaN(d)
}

/**
 * format date based on user timezone
 * @param {Date} date
 * @returns string
 */
export function formatDateFns(date) {
	const dt = new Date(date)
	const dtDateOnly = new Date(dt.valueOf() - dt.getTimezoneOffset() * 60 * 1000)
	return format(dtDateOnly, "dd MMM yyyy, HH:mm")
}

/**
 * function to format date with custom format
 * @param {string} date
 * @param {object} options
 * @returns string
 */
export function formatDate(date, options = { dateStyle: "full" }) {
	if (!date) return

	let d = date
	if (typeof d === "string" || typeof d === "number") {
		d = new Date(d)
	}
	return new Intl.DateTimeFormat("en-SG", options).format(d)
}

/**
 * function to format date with simple format
 * @param {string} date
 * @returns string
 */
export function formatDateSimple(date) {
	return formatDate(date, { month: "short", day: "numeric", year: "numeric" })
}

/**
 * function to format date to medium format
 * @param {Date} date
 * @returns string
 */
export function formatDatePIB(date) {
	return formatDate(date, {
		dateStyle: "medium",
	})
}

/**
 * function that return last index of an array
 * @param {number} index index of an array
 * @param {any[]} arr any[]
 * @returns number
 */
export function at(index, arr) {
	if (index < 0) {
		return arr[arr.length - index]
	} else {
		return arr[index]
	}
}

/**
 * function to add key props for Table datasource
 * @param {any[]} data
 * @returns any
 */
export function addKey(data) {
	if (!data) {
		return data
	}
	if (Array.isArray(data)) {
		return data.map((item, index) => {
			return { ...item, key: index }
		})
	}
	return
}

/**
 * function to check if object is empty or not
 * @param {{string: any}} obj
 * @returns {boolean}
 */
export function isEmptyObject(obj) {
	return Object.keys(obj).length === 0
}

/**
 * function to slice coordinates into two decimal point
 * @param {[number, number]} coords
 * @returns {[number, number]}
 */
export function slicePosition(coords) {
	const [lat, decimalLat] = String(coords[0]).split(".")
	const [long, decimalLong] = String(coords[1]).split(".")
	return [
		Number(lat + "." + decimalLat?.slice(0, 2)),
		Number(long + "." + decimalLong?.slice(0, 2)),
	]
}
