import "./styles/tailwind.css"
import "./styles/index.less"
import "@icon-park/react/styles/index.css"
import "react-datasheet/lib/react-datasheet.css"
import "react-datepicker/dist/react-datepicker.css"
import "core-js/actual"

import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom"
import { QueryCache, QueryClient, QueryClientProvider } from "@tanstack/react-query"

import ErrorBoundary from "./ErrorBoundary/Errorboundary"
import { LOCALSTORAGE_KEY } from "constants/localStorage"
import React from "react"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import ScrollToTop from "utils"
import { ThemeProvider } from "styled-components"
import { message } from "antd"
import { theme } from "styles/theme"

export function catchPageError(fn, retriesLeft = 3, interval = 500) {
	return new Promise((resolve, reject) => {
		fn()
			.then(resolve)
			.catch((err) => {
				console.error(err)
				if (retriesLeft > 0) {
					setTimeout(() => {
						catchPageError(fn, retriesLeft - 1, interval)
							.then(resolve)
							.catch(reject)
					}, interval)
				} else {
					window.location.reload()
					return
				}
			})
	})
}

const DashboardPage = React.lazy(() => catchPageError(() => import("pages/dashboard")))
const MembershipPlanPage = React.lazy(() => catchPageError(() => import("pages/subscribe/Choose")))
const TrackingPlanPage = React.lazy(() => catchPageError(() => import("pages/subscribe/Tracking")))
const SubscribePage = React.lazy(() => catchPageError(() => import("pages/subscribe")))
const TemplatePage = React.lazy(() => catchPageError(() => import("pages/subscribe/Template")))
const PaymentPage = React.lazy(() => catchPageError(() => import("pages/subscribe/Payment-new")))
const DonePage = React.lazy(() => catchPageError(() => import("pages/subscribe/Done")))
const AuthorizationPage = React.lazy(() => catchPageError(() => import("pages/authorization")))
const FinancePage = React.lazy(() => catchPageError(() => import("pages/finance")))
const TrackPage = React.lazy(() => catchPageError(() => import("pages/track")))
const TransformPage = React.lazy(() => catchPageError(() => import("pages/transform")))
const ImportersPage = React.lazy(() => catchPageError(() => import("pages/admin/Importers")))
const CompliancePage = React.lazy(() => catchPageError(() => import("pages/admin/Compliance")))
const DataRepositoryPage = React.lazy(() =>
	catchPageError(() => import("pages/admin/DataRepository")),
)
const PartnersPage = React.lazy(() => catchPageError(() => import("pages/admin/Partners")))
const RegisterSuccessPage = React.lazy(() =>
	catchPageError(() => import("pages/auth/RegisterSuccess")),
)
const WelcomePage = React.lazy(() => catchPageError(() => import("pages/welcome")))
const NotFoundPage = React.lazy(() => catchPageError(() => import("pages/404")))
const DeclarationPage = React.lazy(() => catchPageError(() => import("pages/declaration")))
const LoginPage = React.lazy(() => catchPageError(() => import("pages/auth/Login")))
const RegisterPage = React.lazy(() => catchPageError(() => import("pages/auth/Register")))
const ForgotPage = React.lazy(() => catchPageError(() => import("pages/auth/Forgot")))
const ForgotSuccessPage = React.lazy(() => catchPageError(() => import("pages/auth/ForgotSuccess")))
const ProfilePage = React.lazy(() => catchPageError(() => import("pages/profile")))
const CustomsPage = React.lazy(() => catchPageError(() => import("pages/track/Customs")))
const VesselTrackPage = React.lazy(() => catchPageError(() => import("pages/track/VesselTrack")))
const DocumentsPage = React.lazy(() => catchPageError(() => import("pages/documents")))
const DeclarationWizardPage = React.lazy(() => catchPageError(() => import("pages/sample")))
// const TestPage = React.lazy(() => catchPageError(() => import("PlaygroundDontCommit/index")))

function App() {
	// TODO: This is just temporary -- should be removed once the API's ready
	const globalSettings = JSON.parse(localStorage.getItem(LOCALSTORAGE_KEY.GLOBAL_SETTINGS))

	if (!globalSettings) {
		localStorage.setItem(LOCALSTORAGE_KEY.GLOBAL_SETTINGS, JSON.stringify({ currency: "USD" }))
	}

	// const currentPlan = localStorage.getItem(LOCALSTORAGE_KEY.CURRENT_PLAN)
	// if (!currentPlan) {
	// 	localStorage.setItem(LOCALSTORAGE_KEY.CURRENT_PLAN, "free")
	// }

	const [queryClient] = React.useState(
		() =>
			new QueryClient({
				defaultOptions: {
					queries: {
						refetchOnWindowFocus: false,
						retry: process.env.NODE_ENV === "production" ? 3 : 0,
					},
				},
				queryCache: new QueryCache({
					onError: (error, query) => {
						if (query.state.data !== undefined) {
							message.error(`Something went wrong: ${error.message}`)
						}
					},
				}),
			}),
	)

	return (
		<ErrorBoundary>
			<ThemeProvider theme={theme}>
				<QueryClientProvider client={queryClient}>
					<main id="hkv">
						<BrowserRouter>
							<ScrollToTop />
							<Switch>
								<Redirect exact from="/" to="/dashboard" />
								<Route path="/sample" component={DeclarationPage} />

								<Route path="/dashboard" component={DashboardPage} />
								<Route path="/welcome" component={WelcomePage} />

								<Route path="/login" component={LoginPage} />
								<Route exact path="/register" component={RegisterPage} />
								<Route path="/register/success" component={RegisterSuccessPage} />

								<Route exact path="/forgot" component={ForgotPage} />
								<Route path="/forgot/success" component={ForgotSuccessPage} />

								<Route exact path="/subscribe" component={SubscribePage} />
								<Route path="/subscribe/membership" component={MembershipPlanPage} />
								<Route path="/subscribe/tracking" component={TrackingPlanPage} />
								<Route path="/subscribe/template" component={TemplatePage} />
								<Route path="/subscribe/payment" component={PaymentPage} />
								<Route path="/subscribe/done" component={DonePage} />

								<Route path="/profile" component={ProfilePage} />
								<Route path="/authorization" component={AuthorizationPage} />
								<Route path="/finance" component={FinancePage} />

								<Route exact path="/track" component={TrackPage} />
								<Route path="/track/customs/:customs_number" component={CustomsPage} />
								<Route path="/track/bl/:query" component={VesselTrackPage} />

								<Route path="/admin/customized_invoice" component={TransformPage} />
								<Route path="/admin/importers" component={ImportersPage} />
								<Route path="/admin/partners" component={PartnersPage} />
								<Route path="/admin/compliance" component={CompliancePage} />
								<Route path="/admin/repository" component={DataRepositoryPage} />
								<Route path="/declare" component={DeclarationWizardPage} />
								<Route path="/documents" component={DocumentsPage} />

								{/* {process.env.REACT_APP_ENV === "development" && (
									<Route path="/tests" component={TestPage} />
								)} */}

								<Route component={NotFoundPage} />
							</Switch>
						</BrowserRouter>
					</main>
					<ReactQueryDevtools initialIsOpen={false} />
				</QueryClientProvider>
			</ThemeProvider>
		</ErrorBoundary>
	)
}

export default App
