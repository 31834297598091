import "./i18n"

import * as React from "react"
import * as serviceWorker from "./serviceWorker"

import App from "./App"
import LoadingSkeleton from "components/Loading"
import ReactDOM from "react-dom"

ReactDOM.render(
	<React.StrictMode>
		<React.Suspense fallback={<LoadingSkeleton />}>
			<App />
		</React.Suspense>
	</React.StrictMode>,
	document.getElementById("root"),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
