export const LOCALSTORAGE_KEY = {
	ACCESS_TOKEN: "access_token",
	COUNTRIES: "countries",
	CURRENT_PLAN: "current_plan",
	DECLARE_DATA: "declare_data",
	GLOBAL_SETTINGS: "global_settings",
	I18N: "i18n",
	LANGUAGE: "language",
	MEMBERSHIP: "membership",
	MEMBERSHIP_TYPE: "membership_type",
	PLAN_ACTIVE: "plan_active",
	TR_PELABUHAN: "TR_PELABUHAN",
	TRIAL_CLAIMED: "trial_claimed",
	USER_DETAILS: "user_details",
	PIB_GRID: "pib_grid",
	USER_ACCOUNT_INFO: "user_account_info",
}
