import { Layout, Skeleton, Space } from "antd"

import React from "react"

const LoadingSkeleton = ({ children }) => (
	<Layout>
		<Layout.Content style={{ marginTop: "40px", marginInline: "100px", height: "100vh" }}>
			<div style={{ width: "100%", display: "flex", gap: "16px" }}>
				<Space direction="vertical" style={{ width: "100%" }} size={40}>
					<Skeleton.Button active size="large" shape="round" block />
					<Skeleton.Button size="large" shape="round" block />
					<Skeleton.Button size="large" shape="round" block />
					<Skeleton.Button size="large" shape="round" block />
					<Skeleton.Button size="large" shape="round" block />
					<Skeleton.Button size="large" shape="round" block />
					<Skeleton.Button size="large" shape="round" block />
					<Skeleton.Button size="small" shape="round" block />
					<Skeleton.Button size="small" shape="round" block />
					<Skeleton.Button size="small" shape="round" block />
				</Space>
				<Space direction="vertical" style={{ width: "100%" }} size={40}>
					<Skeleton.Button active size="large" shape="round" block />
					<Skeleton.Button size="large" shape="round" block />
					<Skeleton.Button size="large" shape="round" block />
					<Skeleton.Button size="large" shape="round" block />
					<Skeleton.Button size="large" shape="round" block />
					<Skeleton.Button size="large" shape="round" block />
					<Skeleton.Button size="large" shape="round" block />
					<Skeleton.Button size="small" shape="round" block />
					<Skeleton.Button size="small" shape="round" block />
					<Skeleton.Button size="small" shape="round" block />
				</Space>
			</div>
		</Layout.Content>
	</Layout>
)

export default LoadingSkeleton
