export const isProduction = process.env.REACT_APP_ENV === "production"
export const isDev = process.env.REACT_APP_ENV === "development"

// GENERAL
export const BASE_URL = isProduction
	? "https://smartclear.hakovo.com/Api"
	: "https://ucch-devi.hakovo.com/Api"

export const API_URL = {
	// account
	AUTH_LOGIN: "/Auth/login",
	AUTH_REGISTER: "/Account/Register",
	ONBOARDING_TRADE_PARTNER: "/Account/OnboardingTradePartner",
	// invoice template
	DEFAULT_INVOICE: "/Template/DownloadDefaultInvoiceTemplate",
	DOWNLOAD_INVOICE_TEMPLATE: "/Template/DownloadInvoiceTemplate",
	GET_INVOICE_TEMPLATE_LIST: "/Template/GetInvoiceTemplateList",
	TEMPLATE: "/Template",
	// user profile
	GET_USER_PROFILE: "/Profile/GetUserProfile",
	UPDATE_COMPANY: "/Profile/UpdateCompany",
	// memberships and transactions
	GET_MEMBERSHIPS_PLANS: "/Membership/GetMembershipPlans",
	MEMBERSHIP_SESSION: "/Payment/CreateCheckoutMembershipSession",
	TOPUP_SESSION: "/Payment/CreateCheckoutTopupSession",
	UPGRADE_SESSION: "/Payment/CreateCheckoutUpgradeSession",
	CLAIM_TRIAL: "/Membership/ClaimTrial",
	MEMBERSHIP_LIST: "/Membership/List",
	// seller and owner
	ADD_SELLER: "document/PartialUpdateDMSeller",
	ADD_OWNER: "document/PartialUpdateDMConsingee",
	FIND_TAXES_BY_HS_CODE: "document/FindTaxesByHSCode",
	// documents
	CLONE_DOCUMENT: "document/Clone",
}

export const FETCH_LIST_COUNTRY = BASE_URL + "/Profile/GetCountryNameList"
export const FETCH_EXPORTERS = BASE_URL + "/Exporter/GetList"
export const FETCH_IMPORTERS = BASE_URL + "/Importer/GetList"

// Report
export const REPORT_BASE_URL = isProduction
	? "https://ireport.hakovo.com/Api"
	: "https://ireport-dev.hakovo.com/Api"

export const REPORT_URL = {
	DOCUMENT_HISTORY: "/report/getDocumentHistory",
	SUMMARY: "/report/getSummary",
	ANNUAL_SUMMARY: "/report/getAnnualSummary",
	DATA_REPOSITORY: "/report/getDocumentCompleted",
}

// Tracking
export const TRACKING_BASE_URL = isProduction
	? "https://itracking.hakovo.com/Api"
	: "https://itracking-dev.hakovo.com/Api"

export const TRACKING_LINK_URL = isProduction
	? "https://tracking.hakovo.com/track"
	: "https://tracking-dev.hakovo.com/track"

export const TRACKING_URL = {
	VESSEL_EXTENDED: "/Tracking/VesselExtended",
	UPLOAD_DOCUMENT: "/Manage",
	DOWNLOAD_TEMPLATE: "/Manage/Template",
	DOCUMENT_LIST: "/Manage/List",
	SEARCH_HISTORY: "/Manage/History",
	DELETE_LIST: "/Manage",
}

// Payment
export const PAYMENT_BASE_URL = isProduction
	? "https://payment.hakovo.com/Api"
	: "https://payment-leg.hakovo.com/Api"

export const PAYMENT_URL = {
	ACCOUNT: "/Account",
	GET_MEMBERSHIPS_PLANS: "/Membership/2",
	CHECKOUT_MEMBERSHIP: "/Payment/CreateCheckoutMembershipSession",
	CHECKOUT_TOPUP: "/Payment/CreateCheckoutTopupSession",
	DOWNLOAD_INVOICE: "/Membership/Invoice",
	ORDER_INVOICE_HISTORY: "/Membership/Orders",
	TRANSACTION_HISTORY: "/Membership/Transactions",
}
